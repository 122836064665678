<template>
  <div id="export">
    <el-dialog
      class="btn-2b5a95"
      :title="exportTitle || '导出数据'"
      :visible.sync="transferDialog"
      width="50%"
      center
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="closeExportDialog"
      append-to-body
      v-if="transferDialog"
    >
      <el-transfer
        v-model="transferValue"
        :data="leftData"
        @change="transferChange"
        :titles="['未分配角色', '已分配角色']"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button  @click="closeExportDialog">取消</el-button>
        <el-button
          type="primary"
          @click="onExportClick"
          v-if="showExportBtn"
          :loading="!$store.state.global.isEndRequest"
          >导出</el-button
        >
        <el-button
          type="primary"
          @click="onExportSave"
          :loading="!$store.state.global.isEndRequest"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { myRequest } from "@/request/index";
import { messageTip, createObjParams } from "@/utils/util";
export default {
  props: {
    exportTitle: String,
    transferDialog: Boolean,
    searchForm: Object,
    exportUrl: String,
    exportexcelUrl: String,
    exportsaveUrl: String,
    showExportBtn: {
      type: Boolean,
      default: true,
    },
    leftName: String, //表示传参的字段名
    rightName: String,
    leftLabelKey: String, //表示数据文本的key
    rightIdKey: String,
    lDataName: String, //表示数据字段名
    rDataName: String,
  },

  created() {
    this.getExportRoute();
  },
  data() {
    return {
      transferValue: [], //右侧数据
      leftData: [], //左侧数据
    };
  },
  methods: {
    //获取导出所有项
    getExportRoute() {
      myRequest({
        url: this.exportUrl,
      }).then((res) => {
        if (res.data.code === "200") {
          res.data.data[this.lDataName || "metadatalist1"].forEach((i) => {
            this.leftData.push({
              key: i.id,
              label: i[this.leftLabelKey || "cnpropertyname"],
            });
          });

          this.transferValue = res.data.data[
            this.rDataName || "metadatalist2"
          ].map((i) => i[this.rightIdKey || "id"]);
        }
      });
    },
    //穿梭框内容改变
    transferChange(currentArr, type, originArr) {
      this.transferValue = currentArr;
    },
    //导出 click
    onExportClick() {
      if (this.transferValue.length <= 0) {
        return messageTip({
          message: "请选择需要导出的数据",
          type: "warning",
        });
      }
      this.exportExcel();
    },
    //保存 click api
    onExportSave() {
      myRequest({
        method: "post",
        url: this.exportsaveUrl,
        data: this.$qs.stringify(
          this.rightName
            ? {
                [this.rightName]: this.transferValue,
              }
            : {
                ids1: this.leftData,
                ids2: this.transferValue,
              },
          { arrayFormat: "repeat" }
        ),
      });
    },
    //导出 api
    exportExcel() {
      myRequest({
        method: "post",
        url: this.exportexcelUrl,
        data: this.$qs.stringify(
          {
            ids: this.transferValue,
            id: this.searchForm.id,
            type: this.searchForm.type,
            name: this.searchForm.name,
            regjournalid: this.searchForm.regjournalid,
            schoolorgname: this.searchForm.schoolorgname,
          },
          { arrayFormat: "repeat" }
        ),
      }).then((res) => {
        if (res.status === 200) {
          this.saveExport();
        }
      });
    },
    //导出中。。。
    saveExport() {
      window.location.href =
        this.$store.state.global.baseUrl +
        `${this.exportexcelUrl}?${createObjParams(this.searchForm)}&ids=${
          this.transferValue
        }`;
      messageTip({
        message: "导出成功",
        type: "success",
      });
      this.closeExportDialog();
    },
    //关闭导出框
    closeExportDialog() {
      this.$emit("onCloseExport", false);
    },
  },
};
</script>

<style>
.el-transfer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-transfer__button {
  margin-bottom: 0 !important;
}
</style>
